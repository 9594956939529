import React from 'react';


function Kitchen() {
  return (
    <div className="Cozinha">
      <h1 className="CozinhaTitle">Página cozinha em construção</h1>
    </div>
  );
}
export default Kitchen;
